<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.education.edit.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.add.instructions.header') }}</p>
          <div v-html="$t('message.education.add.instructions.txt')"></div>
        </article>
     </div>
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.edit.form.header') }}</p>
          <div class="columns">
           <div class="column is-full">
               <b-field :label="$t('message.education.add.form.name')">
                  <b-input v-model="newEducation.name"></b-input>
              </b-field>
               <b-field :label="$t('message.education.add.form.category')">
                 <b-autocomplete
                    @typing="(txt) => {
                      updateEducationCategory(txt)
                      updateSuggestions(txt)
                    }"
                    @select="option => {
                      updateEducationCategory(option.name)
                    }"
                    :keep-first="false"
                    :open-on-focus="false"
                    :data="suggestCategories"
                    field="name"
                    :value="newEducation.category_txt" />
              </b-field>
              <b-field :label="$t('message.education.add.form.description')">
                <b-input maxlength="1200" type="textarea" v-model="newEducation.description">></b-input>
              </b-field>

              <div class="columns">
                <div class="column is-half">
                  <b-field :label="$t('message.education.add.form.price')">
                      <b-input maxlength="10" has-counter="false" v-model="newEducation.price"></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field :label="$t('message.education.add.form.pax-limit')">
                      <b-input maxlength="10" has-counter="false" v-model="newEducation.pax_limit"></b-input>
                  </b-field>
                </div>
              </div>
              
              <div class="columns">
                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.add.form.register-start')">
                      <b-datepicker
                        ref="datepicker"
                        :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                        :day-names="['ma','ti','ke','to','pe','la','su']"
                        :date-formatter="_formatDate"
                        v-model="newEducation.register_start">
                      </b-datepicker>
                    </b-field>
                  </section>
                </div>

                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.add.form.register-end')">
                      <b-datepicker
                        ref="datepicker"
                        :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                        :day-names="['ma','ti','ke','to','pe','la','su']"
                        :date-formatter="_formatDate"
                        v-model="newEducation.register_end">
                      </b-datepicker>
                    </b-field>
                  </section>
                </div>
              </div>

              <div class="columns">
                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.add.form.date-start')">
                      <b-datepicker
                        ref="datepicker"
                        :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                        :day-names="['ma','ti','ke','to','pe','la','su']"
                        :date-formatter="_formatDate"
                        v-model="newEducation.course_start">
                      </b-datepicker>
                    </b-field>
                  </section>
                </div>
                <div class="column is-half">
                <section>
                  <b-field :label="$t('message.education.add.form.date-end')">
                    <b-datepicker
                      ref="datepicker"
                      :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                      :day-names="['ma','ti','ke','to','pe','la','su']"
                      :date-formatter="_formatDate"
                      v-model="newEducation.course_end">
                    </b-datepicker>
                  </b-field>
                </section>
                </div>
              </div>

             </div>
            </div>
            <b-button
                :loading="false"
              :label="$t('message.education.edit.send')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="updateEducation" />
        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
            suggestCategories: [],
            newEducation: {
                id: -1,
                name: '',
                description: '',
                price: 0,
                pax_limit: 0,
                category: "",
                category_txt: "",
                course_start: this.$moment()._d,
                course_end: this.$moment()._d,
                register_start: this.$moment()._d,
                register_end: this.$moment()._d,
            },
            errors: {
                is_error: false,
                name: false,
                description: false,
                price: false,
                pax_limit: false,
                course_start: false,
                course_end: false,
                register_start: false,
                register_end: false,
            },
            burger: false,
            isComponentModalActive: false,
            formProps: {
                email: 'evan@you.com',
                password: 'testing'
            }
        }
    },
    methods: {
        updateEducationCategory(txt) {
          this.newEducation.category = txt
        },
        updateEducation() {

            // create category first
            var categoryName = this.newEducation.category

            var promises = []
            var promise = this.$store.dispatch("fetchCategories2")
            promises.push(promises)

            promise.then((response) => {
              var ff = response.data.filter(comp => {
                return (
                    comp.name
                        .toString()
                        .toLowerCase()
                        .indexOf(categoryName.toLowerCase()) >= 0
                )
              })
              if (ff.length == 0) {
                // category doesn't exist yet
                var createCategory = {
                  name: categoryName
                }
                var p2 = this.$store.dispatch("createCategory", createCategory)
                promises.push(p2)
                p2.then((response) => {
                  var status = response.status
                  if (status >= 200 && status < 300) {
                      console.log("created", createCategory)
                  } else {
                      this.toastFailure(this.$t('message.category.add.toaster.creationError'));
                  }
                }).catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(this.$t('message.category.add.toaster.creationError'));
                });
              }
            });

            Promise.all(promises).then(() => {
              var that = this;

              setTimeout(function(){                 
                that.$store.dispatch("fetchCategories2").then((response) => {
                  var ff = response.data.filter(comp => {
                      return (
                          comp.name
                              .toString()
                              .toLowerCase()
                              .indexOf(categoryName.toLowerCase()) >= 0
                      )})

                  var categoryId = ff[0].id
                  var real_education = {
                      id: that.newEducation.id,
                      name: that.newEducation.name,
                      description: that.newEducation.name,
                      price: that.newEducation.price,
                      pax_limit: that.newEducation.pax_limit,
                      category_id: categoryId,
                      education_partner_id: that.user.education_partner_id,
                      course_start: that.$moment(that.newEducation.course_start).format('YYYY-MM-DD'),
                      course_end: that.$moment(that.newEducation.course_end).format('YYYY-MM-DD'),
                      register_start: that.$moment(that.newEducation.register_start).format('YYYY-MM-DD'),
                      register_end: that.$moment(that.newEducation.register_end).format('YYYY-MM-DD'),
                  }
                  that.$store.dispatch("editEducation", real_education).then((response) => {
                      var status = response.status
                      if (status >= 200 && status < 300) {
                          that.$router.push('/koulutukset')
                      } else {
                          that.toastFailure(that.$t('message.education.edit.toaster.creationError'));
                      }
                  }).catch((error) => {
                      that.ERROR(error);
                      that.toastFailure(that.$t('message.education.edit.toaster.creationError'));
                  });
                })
              }, 1000);

            });
        },
        updateSuggestions(txt) {
            var ff = this.categories.filter(comp => {
                return (
                    comp.name
                        .toString()
                        .toLowerCase()
                        .indexOf(txt.toLowerCase()) >= 0
                )
            })
            this.suggestCategories = ff
        },
    },

    computed: {
        user() {
            return this.$store.getters['getUser']
        }, 
        education() {
          var __id = parseInt(this.$route.params.education_id, 10)
          var educations = this.$store.getters['getCourses']
          var education = educations.find(ed => ed.id == __id)
          return education
        },
        categories() {
            return this.$store.getters['getCategories']
        }, 
    },
    mounted() {
      this.$store.dispatch("fetchCategories");
      this.$store.dispatch("fetchEducations");
      this.$store.dispatch("fetchEducations3").then((response) => {

          var __id = parseInt(this.$route.params.education_id, 10)
          var educations = response.data
          var education = educations.find(ed => ed.id == __id)

          this.newEducation.id = education.id
          this.newEducation.name = education.name
          this.newEducation.description = education.description
          this.newEducation.price = education.price
          this.newEducation.pax_limit = education.pax_limit

          this.newEducation.course_start = this.$moment(education.course_start, "YYYY-MM-DD hh:mm:ss")._d
          this.newEducation.course_end = this.$moment(education.course_end, "YYYY-MM-DD hh:mm:ss")._d
          this.newEducation.register_start = this.$moment(new Date(education.register_start))._d
          this.newEducation.register_end = this.$moment(new Date(education.register_end))._d
          
          this.$store.dispatch("fetchCategories2").then((response) => {
              var cat_text = response.data.find(cat => cat.id == education.category_id)
              if (cat_text) {
                  this.updateEducationCategory(cat_text.name)
                  this.newEducation.category_txt = cat_text.name
              }
          })
      })
    }
}
</script>